import React from 'react';
import { Proposal } from '../../types/rankifyGame';
import useIpfs from '../../hooks/useIpfs';

export const ProposalTitle: React.FC<{ proposal: Proposal }> = ({ proposal }) => {
    const ipfsContentQuery = useIpfs({ ipfsLink: proposal?.ipfs });

    if (ipfsContentQuery.isLoading) return <>Loading proposal title...</>;

    if (ipfsContentQuery.isError) return <span style={{ color: "red" }}>Error fetching proposal title</span>;

    if(proposal.title) {
        return <>{proposal.title}</>;
    }

    if (Boolean(proposal.ipfs) && ipfsContentQuery.isSuccess) {
        return <>{(ipfsContentQuery.data as unknown as Proposal).title}</>;
    }

    return  <></>
};

export default ProposalTitle;
