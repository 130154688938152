import React, { useLayoutEffect, useState } from 'react';
import {
    Card, Text, Group, Badge, Progress, Stack, Button, Box, Tooltip, Transition,
    Grid, Loader, Divider, Image,
    Anchor
} from '@mantine/core';
import { GamePhase, type GameState, VoteEvent, ProposalEvent } from '../../types/rankifyGame';
import LevelThemeIcon from '../LevelThemeIcon';
import { humanReadableTimeDistance } from '../../utils/time';
import { useWeb3Context } from '../../providers/Web3Provider/useWeb3Context';
import { SHOW_MOCKS } from '../../config';
import JoinGameButton from './JoinGameButton';
import { Address } from 'viem';
import { IconLogin2 } from '@tabler/icons-react';
import { useAppRouter } from '../../hooks';
import LeaveGameButton from './LeaveGameButton';
import StartGameButton from './StartGameButton';
import { useRankifyGame, generateInputProps } from '../../hooks/useRankifyGame';
import { Countdown } from '../Countdown';
import { AlertMessage } from '../AlertMessage';
import Username from '../Username';
import { RankTokenMetadata } from '../../types/rankToken';
import { getImageUrl } from '../../utils/metadata';
import { getPlace } from '../../utils/game';
import VoteButton from './VoteButton';
import ProposeButton from './ProposeButton';
import GameSettingsInfo from './GameSettingsInfo';

type GameCardProps = {
    game: GameState;
    rankifyInstanceAddress: Address;
    fellowshipMetadata?: RankTokenMetadata;
    proposals?: ProposalEvent[];
    votes?: VoteEvent[];
    maoInstanceId?: number;
    mode?: 'list' | 'full' | 'dashboard';
}

const GameCard: React.FC<GameCardProps> = ({ game, rankifyInstanceAddress, fellowshipMetadata, votes, proposals,
    maoInstanceId, mode = 'list' }) => {
    const web3Context = useWeb3Context();
    const { useGetPlayers } = useRankifyGame(generateInputProps({ web3ProviderContext: web3Context, instanceAddress: rankifyInstanceAddress, gameId: game.id }));
    const playersQuery = useGetPlayers();
    const [isMounted, setIsMounted] = useState(false);
    const router = useAppRouter();
    const proposed = proposals?.some(event => event.args.proposer === web3Context.account) ?? false;
    const voted = votes?.some(event => event.args.player === web3Context.account) ?? false;
    const actionNeeded = !proposed || !voted;

    useLayoutEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsMounted(true);
        }, 100);

        return () => clearTimeout(timeoutId);
    }, []);

    if (playersQuery.isLoading) return <Loader />;

    if (playersQuery.isError) return (
        <AlertMessage
            message="There was an error fetching data! Please try again."
        />
    );

    const handleDetailsButtonClick = () => {
        router.push(`/game/${maoInstanceId}/${rankifyInstanceAddress}/${game.id}`);
    };

    const startDateWithLabelBasedOnPhase = (): string => {
        switch (game.gamePhase) {
            case GamePhase.CREATED:
                return '';
            case GamePhase.OPEN:
                return 'Opened ' + humanReadableTimeDistance(game.registrationOpenAt, true, !SHOW_MOCKS);
            case GamePhase.IN_PROGRESS:
            case GamePhase.LAST_TURN:
            case GamePhase.OVERTIME:
                return 'Started ' + humanReadableTimeDistance(game.startedAt, true, !SHOW_MOCKS);
            case GamePhase.FINISHED:
                return 'Finished';
            default:
                return '';
        }
    };

    const distanceTolNextActionBasedOnPhase = (): React.ReactNode | string => {
        switch (game.gamePhase) {
            case GamePhase.CREATED:
                return 'Join requirements must be set by creator';
            case GamePhase.OPEN:
                if (game.registrationOpenAt + game.timeToJoin < (new Date()).getTime()) {
                    return <>Minimum <Text component="span" fw={700}>{game.minPlayerCnt}</Text> players needed.
                        Waiting for more players to start the game.</>;
                }
                return <>Minimum <Text component="span" fw={700}>{game.minPlayerCnt}</Text> players.
                    Starts {humanReadableTimeDistance(game.registrationOpenAt + game.timeToJoin, true, !SHOW_MOCKS)} or maximum players reached</>;
            case GamePhase.IN_PROGRESS:
            case GamePhase.LAST_TURN:
            case GamePhase.OVERTIME:
                return (game.gamePhase === GamePhase.IN_PROGRESS ? 'Turn ends ' : 'The game ends ')
                    + humanReadableTimeDistance(game.turnStartedAt + game.timePerTurn, true, !SHOW_MOCKS) + ' or all players have made a move.';
            case GamePhase.FINISHED:
                return 'Game is finished — check the results!';
            default:
                return '';
        }
    }

    const playerLabelBasedOnPhase = (): string => {
        switch (game.gamePhase) {
            case GamePhase.CREATED:
                return 'Players';
            case GamePhase.OPEN:
                return 'Players joined';
            case GamePhase.IN_PROGRESS:
            case GamePhase.LAST_TURN:
            case GamePhase.OVERTIME:
                return 'Players made move';
            case GamePhase.FINISHED:
                return 'Players';
            default:
                return '';
        }
    }

    const playersBasedOnPhase = (): string => {
        switch (game.gamePhase) {
            case GamePhase.CREATED:
            case GamePhase.OPEN:
                return `${playersQuery.data?.length}/${game.maxPlayerCnt}`;
            case GamePhase.IN_PROGRESS:
            case GamePhase.LAST_TURN:
            case GamePhase.OVERTIME:
                return `${game.numPlayersMadeMove}/${game.numActivePlayers}`;
            case GamePhase.FINISHED:
                return `${game.numActivePlayers}`;
            default:
                return '';
        }
    }

    const turnsBasedOnPhase = (): string => {
        switch (game.gamePhase) {
            case GamePhase.OPEN:
            case GamePhase.CREATED:
            case GamePhase.IN_PROGRESS:
            case GamePhase.LAST_TURN:
            case GamePhase.OVERTIME:
                return `Turn ${game.currentTurn}/${game.maxTurns}`;
            case GamePhase.FINISHED:
                return `Turn ${game.currentTurn - 1}/${game.maxTurns}`;
            default:
                return '';
        }
    }

    const progressBasedOnPhase = (): number => {
        switch (game.gamePhase) {
            case GamePhase.CREATED:
            case GamePhase.OPEN:
                return 0;
            case GamePhase.IN_PROGRESS:
            case GamePhase.LAST_TURN:
            case GamePhase.OVERTIME:
                return (game.currentTurn / (game.maxTurns + 1)) * 100;
            case GamePhase.FINISHED:
                return 100;
            default:
                return 0;
        }
    }

    const buttonsBasedOnPhase = (): React.ReactNode => {
        switch (game.gamePhase) {
            case GamePhase.CREATED:
                return web3Context.account === game.createdBy ? (
                    <Button variant="light" fullWidth>
                        Set join requirements
                    </Button>
                ) : (
                    <></>
                );
            case GamePhase.OPEN:
                return <>
                    <Grid columns={24} gutter="1rem">
                        <Grid.Col span={{ base: 24, sm: mode === 'full' ? 8 : 24 }}>
                            <Group grow>
                                <JoinGameButton gameId={game.id} rankifyInstanceAddress={rankifyInstanceAddress}
                                    fullWidth />
                            </Group>
                        </Grid.Col>
                        {mode === 'full' && (
                            <>
                                <Grid.Col span={{ base: 24, sm: 8 }}>
                                    <LeaveGameButton gameId={game.id} rankifyInstanceAddress={rankifyInstanceAddress} fullWidth />
                                </Grid.Col>
                                <Grid.Col span={{ base: 24, sm: 8 }}>
                                    <StartGameButton gameId={game.id} rankifyInstanceAddress={rankifyInstanceAddress} fullWidth />
                                </Grid.Col>
                            </>
                        )}
                    </Grid>
                </>;
            case GamePhase.IN_PROGRESS:
            case GamePhase.LAST_TURN:
            case GamePhase.OVERTIME:
            case GamePhase.FINISHED:
            default:
                return <></>;
        }
    }

    const badgeBasedOnPhase = (): React.ReactNode => {
        return (<Badge
            variant="filled"
            fw="bold"
            size="lg"
            color={
                game.gamePhase === GamePhase.CREATED ? 'gray' :
                    game.gamePhase === GamePhase.OPEN ? 'violet.9' :
                        game.gamePhase === GamePhase.IN_PROGRESS ? 'blue.9' :
                            game.gamePhase === GamePhase.LAST_TURN ? 'orange' :
                                game.gamePhase === GamePhase.OVERTIME ? 'red.9' :
                                    game.gamePhase === GamePhase.FINISHED ? 'green.9' : 'gray'
            }
        >
            {game.gamePhase}
        </Badge>);
    }

    const myPosition = (): string => {
        const playerIndex = game.players?.findIndex(player => player === web3Context.account);
        const place = getPlace(game.scores?.[1], game.scores?.[1][playerIndex ?? 0]);
        switch (place) {
            case 1:
                return '🥇 1st';
            case 2:
                return '🥈 2nd';
            case 3:
                return '🥉 3rd';
            default:
                return `${place}th`;
        }
    }

    const gameTitle = (showStartedAt: boolean): React.ReactNode => {
        if (mode !== 'full')
            return (
                <Group align="baseline" justify='space-between'>
                    <Anchor href={`/game/${maoInstanceId}/${rankifyInstanceAddress}/${game.id}`}
                        fz="lg" fw={500} mt="md" c="gray.4"
                        onClick={e => { e.preventDefault(); router.push(`/game/${maoInstanceId}/${rankifyInstanceAddress}/${game.id}`) }}>
                        <Group gap="3px">Game #{game.id} <Tooltip label="Open game details" position="top"><IconLogin2 /></Tooltip></Group>
                    </Anchor>
                    {showStartedAt && (<Text fz="sm" c="dimmed">
                        started {humanReadableTimeDistance(game.startedAt, true, !SHOW_MOCKS)}
                    </Text>)}
                </Group>)
        if (mode === 'full') {
            return (
                <>
                    <Text fz="lg" fw={500} mt="md" c="gray.4">
                        Game #{game.id}
                    </Text>
                </>
            )
        };
    };

    return (
        <Transition transition="pop" exitDelay={100} mounted={isMounted} duration={300}>
            {(transitionStyle) => (
                <Card withBorder padding="md" radius="md" w="100%" style={{
                    height: '100%',
                    display: 'flex',
                    overflowY: 'auto',
                    ...transitionStyle
                }}>

                    {mode === 'dashboard' && (<><Card.Section ta="center" h="180px" pos="relative">
                        <Group mt="sm" mx="md" justify="space-between">
                            {badgeBasedOnPhase()}
                            <Tooltip label={"Level " + game.rank} position="top">
                                <LevelThemeIcon level={game.rank} />
                            </Tooltip>
                        </Group>
                        <Image h="calc(100% - 70px)" w="100%" fit="contain" mt="0"
                            src={getImageUrl(fellowshipMetadata?.image)}
                            alt={fellowshipMetadata?.name + " image"} />
                    </Card.Section>

                        <Divider my="0" mx="-sm" /></>)}

                    <Stack justify="space-between" h="100%" gap={0}>
                        {mode !== 'dashboard' && (<>
                            <Group justify="space-between">
                                <Group>
                                    {badgeBasedOnPhase()}
                                    {mode === 'full' && (game.gamePhase === GamePhase.IN_PROGRESS || game.gamePhase === GamePhase.LAST_TURN || game.gamePhase === GamePhase.OVERTIME)
                                        && <Countdown timePerTurn={game.timePerTurn} turnStartedAt={game.turnStartedAt} />}
                                </Group>
                                <Tooltip label={"Level " + game.rank} position="top">
                                    <LevelThemeIcon level={game.rank} />
                                </Tooltip>
                            </Group>

                            {mode === 'full' && (<><Divider my="md" mx="-sm" />
                            <GameSettingsInfo gameState={game} />
                            <Divider mt="md" mx="-md" /></>)}

                            {gameTitle(false)}

                            <Text fz="sm" c="dimmed" mt={5}>
                                {distanceTolNextActionBasedOnPhase()}
                            </Text>
                            <Text c="dimmed" fz="sm" mt="md">
                                {playerLabelBasedOnPhase()}: {' '}
                                <Text span fw={500} c="bright">
                                    {playersBasedOnPhase()}
                                </Text>
                            </Text>

                            <Stack justify="space-between" mt="md" gap={0}>
                                <Progress value={progressBasedOnPhase()} mt={5} color="teal" />

                                <Group justify="space-between" mt="md">
                                    <Text fz="sm" c="dimmed">
                                        {turnsBasedOnPhase()}
                                    </Text>
                                    <Text fz="sm" c="dimmed">
                                        {startDateWithLabelBasedOnPhase()}
                                    </Text>
                                </Group>
                            </Stack></>)}

                        {mode === 'dashboard' && (<>
                            {gameTitle(true)}
                            <Text fz="sm" c="dimmed" mt={5}>
                                in {fellowshipMetadata?.name}
                            </Text>
                            <Divider my="md" mx="-sm" />
                            <Group justify='space-between' wrap="nowrap" py="5px">
                                <Group gap="3px" align="end">
                                    <Text size="24px" c="gray.5">{game.numActivePlayers}</Text>
                                    <Text size="13px" mb="2px" c="gray.5">Players</Text>
                                </Group>
                                <Group gap="3px" align="end">
                                    <Text size="24px" c="gray.5">{game.currentTurn}/{game.maxTurns}</Text>
                                    <Text size="13px" mb="2px" c="gray.5">Turn</Text>
                                </Group>
                                <Group gap="3px" align="end">
                                    <Text size="24px" c="gray.5">{myPosition()}</Text>
                                    <Text size="13px" mb="2px" c="gray.5">Place</Text>
                                </Group>
                            </Group>
                            <Divider my="md" mx="-sm" />

                            <Group justify="space-between" mt="xs">
                                <Group><Countdown timePerTurn={game.timePerTurn} turnStartedAt={game.turnStartedAt} size="lg" /></Group>
                                <Badge variant="filled" fw="bold" size="lg"
                                    color={actionNeeded ? 'orange.9' : 'violet.9'}>
                                    {actionNeeded ? 'Action needed' : 'Waiting for others'}
                                </Badge>
                            </Group>
                        </>)}

                        {mode !== 'dashboard' && (<Box mt="md">
                            {buttonsBasedOnPhase()}
                        </Box>)}

                        {mode === 'dashboard' && (<Group mt="md" grow>
                            <VoteButton gameState={game} rankifyInstanceAddress={rankifyInstanceAddress} />
                            <ProposeButton gameState={game} rankifyInstanceAddress={rankifyInstanceAddress} />
                        </Group>)}

                        <Group gap="xs" mt="sm">
                            <Text fz="sm" c="dimmed">Created by:</Text>
                            <Username address={game.createdBy as Address} />
                        </Group>
                    </Stack>
                </Card>
            )}
        </Transition>
    );
};

export default GameCard;
