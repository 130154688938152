import { useQuery } from "@tanstack/react-query";
import { queryPublic } from "../utils";
import { queryCacheProps } from "./hookCommon";
import { IPFS_GATEWAY } from "../config";

const useIpfs = ({ ipfsLink }: { ipfsLink: string | undefined }) => {
    const link = ipfsLink?.replace("ipfs://", IPFS_GATEWAY);

    const ipfsContentQuery = useQuery({
        queryKey: ["useURI", link],
        queryFn: async (query: any) => {
            const link = query.queryKey[1].replace("ipfs://", IPFS_GATEWAY);
            return queryPublic(link).then((r: any) => r.data);
        },
        ...queryCacheProps,
        refetchInterval: false,
        enabled: Boolean(link),
    });

    return ipfsContentQuery;
};

export default useIpfs;
