import React from 'react';
import { Button, Modal, Text, Group, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useWeb3Context } from '../../providers/Web3Provider/useWeb3Context';
import { Address } from 'viem';
import useNotification from '../../hooks/useNotification';
import { useRankifyGame, usePlayersGame, useRankifyGameState, generateInputProps as useRankifyGameInputProps } from '../../hooks/useRankifyGame';

const StartGameButton: React.FC<{
    gameId: number;
    fullWidth?: boolean;
    rankifyInstanceAddress: Address;
    onClose?: (gameId?: number) => void;
}> = ({
    gameId,
    fullWidth = false,
    rankifyInstanceAddress,
    onClose
}) => {
    const [opened, { open, close }] = useDisclosure(false);
    const web3Context = useWeb3Context();
    const notification = useNotification();
    const { startGameMutation } = useRankifyGame(useRankifyGameInputProps({ web3ProviderContext: web3Context, instanceAddress: rankifyInstanceAddress, gameId: gameId }));
    const { isInTheGame } = usePlayersGame(useRankifyGameInputProps({ web3ProviderContext: web3Context, instanceAddress: rankifyInstanceAddress, gameId: gameId }));
    const { gameStateDetailsQuery } = useRankifyGameState(useRankifyGameInputProps({ web3ProviderContext: web3Context, instanceAddress: rankifyInstanceAddress, gameId: gameId }));

    const handleConfirmStart = async () => {
        try {
            await startGameMutation.mutateAsync(undefined, {
                onSuccess: () => {
                    gameStateDetailsQuery.refetch();
                    notification("Game started successfully!", "success");
                    onClose && onClose(gameId);
                }
            });
            close();
        } catch (error) {
            console.error('Error starting game:', error);
            close();
        }
    };

    return (
        <>
            <Tooltip label={!isInTheGame ? "Only players who joined can start the game" : "All starting requirements are no met"}
                disabled={isInTheGame && gameStateDetailsQuery.data?.canStart}>
                <Button
                    variant="light"
                    color="teal"
                    onClick={open}
                    fullWidth={fullWidth}
                    loading={gameStateDetailsQuery.isLoading}
                    disabled={!isInTheGame || !gameStateDetailsQuery.data?.canStart}
                >
                    Start Game
                </Button>
            </Tooltip>

            <Modal opened={opened} onClose={close} title="Confirm Start" centered>
                <Text size="sm" mb="lg">
                    Are you sure you want to start this game?
                </Text>

                <Group justify="flex-end">
                    <Button variant="outline" onClick={close}>Cancel</Button>
                    <Button
                        onClick={handleConfirmStart}
                        loading={startGameMutation.isPending || gameStateDetailsQuery.isLoading}
                    >
                        Start
                    </Button>
                </Group>
            </Modal>
        </>
    );
};

export default StartGameButton;
