import React from 'react';
import { Button, Modal, Text, Group, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useWeb3Context } from '../../providers/Web3Provider/useWeb3Context';
import { Address } from 'viem';
import useNotification from '../../hooks/useNotification';
import { useRankifyGame, usePlayersGame, generateInputProps as useRankifyGameInputProps } from '../../hooks/useRankifyGame';
import { useQueryClient } from '@tanstack/react-query';

const JoinGameButton: React.FC<{
    gameId: number; fullWidth?: boolean;
    rankifyInstanceAddress: Address; onClose?: (gameId?: number) => void;
}> = ({
    gameId, fullWidth = false, rankifyInstanceAddress, onClose
}) => {
        const [opened, { open, close }] = useDisclosure(false);
        const web3Context = useWeb3Context();
        const notification = useNotification();
        const { playersGameQuery, isInTheGame } = usePlayersGame(useRankifyGameInputProps({ web3ProviderContext: web3Context, instanceAddress: rankifyInstanceAddress, gameId: gameId }));
        const { joinGameMutation } = useRankifyGame(useRankifyGameInputProps({ web3ProviderContext: web3Context, instanceAddress: rankifyInstanceAddress, gameId: gameId }));
        const queryClient = useQueryClient();

        const handleConfirmJoin = async () => {
            try {
                await joinGameMutation.mutateAsync(undefined, {
                    onSuccess: () => {
                        notification("Successfully joined game", "success");
                        playersGameQuery.refetch();
                        queryClient.invalidateQueries({ queryKey: ["RankifyGame", "getGameStateDetails", web3Context.account, rankifyInstanceAddress, gameId] });
                        queryClient.invalidateQueries({ queryKey: ["RankifyGame", "getPlayers", web3Context.account, rankifyInstanceAddress, gameId] });
                        onClose && onClose(gameId);
                    }
                });
                close();
            } catch (error) {
                console.error('Error joining game:', error);
                close();
            }
        };

        return (
            <>
                <Tooltip disabled={Number(playersGameQuery.data) === 0} label={isInTheGame ? 'Already joined the game' : 'Already joined another game (only one game allowed at a time).'}>
                    <Button variant="light" onClick={open} fullWidth={fullWidth}
                        loading={playersGameQuery.isLoading}
                        disabled={Number(playersGameQuery.data) > 0}
                    >
                        {Number(playersGameQuery.data) === 0 ? 'Join' : isInTheGame ? 'Joined' : 'Can\'t join' }
                    </Button>
                </Tooltip>

                <Modal opened={opened} onClose={close} title="Confirm Join" centered>
                    <Text size="sm" mb="lg">
                        Are you sure you want to join this game?
                    </Text>

                    <Group justify="flex-end">
                        <Button variant="outline" onClick={close}>Cancel</Button>
                        <Button
                            onClick={handleConfirmJoin}
                            loading={joinGameMutation.isPending || playersGameQuery.isLoading}
                            disabled={isInTheGame}
                        >
                            Join
                        </Button>
                    </Group>
                </Modal>
            </>
        );
    };

export default JoinGameButton;
