import React from 'react';
import { ThemeIcon, ThemeIconProps } from '@mantine/core';
import { levelToIcon } from '../utils/levelIcons';

interface LevelThemeIconProps extends Omit<ThemeIconProps, 'children'> {
    level: number;
}

export const LevelThemeIcon = React.forwardRef<HTMLDivElement, LevelThemeIconProps>(
    ({ level, ...props }, ref) => {
        const Icon = levelToIcon(level);

        return (
            <ThemeIcon
                ref={ref}
                variant="gradient"
                size="xl"
                gradient={{ from: 'teal', to: 'teal.9', deg: 90 }}
                {...props}
            >
                <Icon size={24} />
            </ThemeIcon>
        );
    }
);

LevelThemeIcon.displayName = 'LevelThemeIcon';

export default LevelThemeIcon;
