import { Group, Button } from '@mantine/core';
import React from 'react';
import { IconStar, IconStarFilled } from '@tabler/icons-react';
import classes from './VotingStars.module.css';
import { motion } from 'framer-motion';
import { glowingEffect } from '../../../styles/animations';

interface VotingStarsProps {
    pointsTotal: number;
    pointsLeft: number;
    pointsSetInitially: number;
    disabled: boolean;
    scoreSet: (points: number) => void;
    scoreUnset: (points: number) => void;
}

export const VotingStars = React.forwardRef<{ reset: () => void }, VotingStarsProps>(({
    pointsTotal,
    pointsLeft,
    pointsSetInitially,
    disabled,
    scoreSet,
    scoreUnset,
}, ref) => {
    const [pointsSet, setPointsSet] = React.useState(pointsSetInitially);

    React.useImperativeHandle(ref, () => ({
        reset: () => {
            if (pointsSet > 0) {
                scoreUnset(pointsSet);
                setPointsSet(0);
            }
        }
    }));

    // Calculate maximum possible votes based on quadratic voting
    const getMaxVotes = (points: number): number => {
        return Math.floor(Math.sqrt(points));
    };

    const maxPossibleVotes = getMaxVotes(pointsTotal);

    const updateScore = (points: number) => {
        if (pointsSet > 0) {
            scoreUnset(points);
            setPointsSet(0);
        } else {
            scoreSet(points);
            setPointsSet(points);
        }
    };

    return (
        <Group gap="3px" justify="flex-end">
            {[...Array(maxPossibleVotes)].map((_, index) => {
                const votes = index + 1;
                const quadraticCost = votes * votes;
                const isAffordable = quadraticCost <= pointsLeft;

                return (
                    <motion.span
                        key={index}
                        initial={{ scale: 1 }}
                        animate={{ scale: [1, 1.5, 1], rotate: [0, 20, -20, 0] }}
                        transition={{
                            duration: 0.4,
                            ease: 'easeInOut',
                        }}
                        whileTap={(!isAffordable && pointsSet < quadraticCost) ? undefined : { scale: [1, 1.5, 1], rotate: [0, 20, -20, 0] }}
                        style={{
                            display: 'inline-block', color: 'orange.5',
                            textShadow: '0 0 8px rgba(255, 215, 0, 0.8)',
                        }}
                        className={glowingEffect}
                    >
                        <Button
                            key={votes} onClick={() => updateScore(pointsSet === 0 ? quadraticCost : pointsSet)}
                            variant="transparent" className={classes.starButton}
                            c={pointsSet >= quadraticCost ? 'orange.5' : ''}
                            disabled={disabled || (!isAffordable && pointsSet < quadraticCost)}
                            title={`Cost: ${quadraticCost} points`} px="0"

                        >
                            {pointsSet < quadraticCost && (<IconStar size={20} />)}
                            {pointsSet >= quadraticCost && (<IconStarFilled size={20} />)}
                        </Button>
                    </motion.span>
                );
            })}
        </Group>
    );
});

VotingStars.displayName = 'VotingStars';

export default VotingStars;
