import { useState, useEffect } from 'react';
import { Text, Group, Tooltip, Box, Progress } from '@mantine/core';
import { IconAlarm } from '@tabler/icons-react';

export const Countdown: React.FC<{ timePerTurn: number; turnStartedAt: number; size?: string; }> = ({ timePerTurn, turnStartedAt, size = 'lg' }) => {
    const [timeLeft, setTimeLeft] = useState(0);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            const elapsed = Math.floor(Date.now() / 1000) - turnStartedAt;
            const remaining = Math.max(timePerTurn - elapsed, 0);
            setTimeLeft(remaining);
            setMounted(true);
        }, 0);

        return () => clearInterval(interval);
    }, [timePerTurn, turnStartedAt]);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const hours = Math.floor(minutes / 60);
        const displayMinutes = hours > 0 ? minutes % 60 : minutes;
        return [
            hours > 0 ? String(hours).padStart(2, '0') : null,
            String(displayMinutes).padStart(2, '0'),
            String(remainingSeconds).padStart(2, '0'),
        ]
            .filter(Boolean)
            .join(':');
    };

    if (!mounted) {
        return <></>;
    }

    return (
        <Tooltip label="Time left till next turn">
            <Group gap="5px" align="center">
                <Box visibleFrom="sm" mt="6px"><IconAlarm size={size === 'lg' ? 24 : 18} /></Box>
                <Text align="center" size={size} weight={500} c={timeLeft > 300 ? 'gray.1' : 'red'}>
                    {timeLeft > 0 ? formatTime(timeLeft) : 'Time\'s up!'}
                </Text>
            </Group>
        </Tooltip>
    );
}
