import { Address, Hex, PublicClient, WalletClient } from "viem";

export enum GamePhase {
  CREATED = "Game created",
  OPEN = "Registration open",
  IN_PROGRESS = "In progress",
  LAST_TURN = "Playing last turn",
  OVERTIME = "PLaying in overtime",
  FINISHED = "Finished"
}

export interface GameState {
  id: number;
  createdBy: string;
  currentTurn: number;
  gameMaster: string;
  gamePhase: GamePhase;
  hasEnded: boolean;
  hasStarted: boolean;
  isOvertime: boolean;
  isOpen?: boolean;
  maxPlayerCnt: number;
  maxTurns: number;
  minGameTime: number;
  minPlayerCnt: number;
  numActivePlayers: number;
  numCommitments: number;
  numOngoingProposals: number;
  numPlayersMadeMove: number;
  numPrevProposals: number;
  numVotesPrevTurn: number;
  numVotesThisTurn: number;
  rank: number;
  registrationOpenAt: number;
  startedAt: number;
  timePerTurn: number;
  timeToJoin: number;
  turnStartedAt: number;
  voteCredits: number;
  canStart: boolean;
  scores?: readonly [readonly Address[], number[]];
  players?: readonly Address[];
  voting: {
    maxQuadraticPoints: number;
    minQuadraticPositions: number;
    voteCredits: number;
  }
}

export interface ProposalEvent {
  address: Address;
  args: {
    gameId: number;
    turn: number;
    proposer: Address;
    commitmentHash: Address;
    proposalEncryptedByGM: string;
  };
  blockHash: Address;
  blockNumber: number;
  blockTimestamp: Address;
  data: Address;
  eventName: "ProposalSubmitted";
  logIndex: number;
  removed: boolean;
  topics: [Address, Address, Address, Address];
  transactionHash: Address;
  transactionIndex: number;
}

export interface VoteEvent {
  address: Address;
  args: {
    gameId: number;
    turn: number;
    player: Address;
    votesHidden: Address;
  };
  blockHash: Address;
  blockNumber: number;
  blockTimestamp: Address;
  data: Address;
  eventName: "VoteSubmitted";
  logIndex: number;
  removed: boolean;
  topics: [Address, Address, Address, Address];
  transactionHash: Address;
  transactionIndex: number;
}

export interface Proposal {
  ipfs?: string;
  title?: string;
  body?: string;
}

export interface HistoricTurn {
  address: Address;
  args: {
    gameId: number;
    turn: number;
    players: Address[];
    scores: number[];
    newProposals: Proposal[];
    oldVotes: number[];
    newVotes: number[];
    newProposalIndices: number[];
    votes: number[][]
  };
  blockHash: Hex;
  blockNumber: bigint;
  blockTimestamp: string;
  data: string;
  eventName: "TurnEnded";
  logIndex: number;
  removed: boolean;
  topics: [Hex, Hex, Hex];
  transactionHash: Hex;
  transactionIndex: number;
}

export interface VotingQueryResult {
  proposalEvents?: ProposalEvent[];
  voteEvents?: VoteEvent[];
}

export interface OngoingProposals {
    currentTurn: number,
    proposals: Proposal[],
}

export interface ProposalForVoting {
    id: number
    proposal: Proposal,
    score: number,
    isCurrentPlayerProposal: boolean
}

export interface ScoreListItem {
    player: Address,
    score: number,
}

export interface useRankifyGameProps {
    publicClient: PublicClient;
    walletClient: WalletClient;
    chainId: number;
    instanceAddress: Address;
    account: Address;
    gameId: number;
}