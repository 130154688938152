import React from 'react';
import { Button, Modal, Text, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useWeb3Context } from '../../providers/Web3Provider/useWeb3Context';
import { Address } from 'viem';
import useNotification from '../../hooks/useNotification';
import { useRankifyGame, usePlayersGame, generateInputProps as useRankifyGameInputProps } from '../../hooks/useRankifyGame';
import { useQueryClient } from '@tanstack/react-query';

const LeaveGameButton: React.FC<{
    gameId: number;
    fullWidth?: boolean;
    rankifyInstanceAddress: Address;
    onClose?: (gameId?: number) => void;
}> = ({
    gameId,
    fullWidth = false,
    rankifyInstanceAddress,
    onClose
}) => {
    const [opened, { open, close }] = useDisclosure(false);
    const web3Context = useWeb3Context();
    const notification = useNotification();
    const { playersGameQuery, isInTheGame } = usePlayersGame(useRankifyGameInputProps({ web3ProviderContext: web3Context, instanceAddress: rankifyInstanceAddress, gameId: gameId }));
    const { leaveGameMutation } = useRankifyGame(useRankifyGameInputProps({ web3ProviderContext: web3Context, instanceAddress: rankifyInstanceAddress, gameId: gameId }));
    const queryClient = useQueryClient();

    const handleConfirmLeave = async () => {
        try {
            await leaveGameMutation.mutateAsync(undefined, {
                onSuccess: () => {
                    notification("Successfully left the game", "success");
                    playersGameQuery.refetch();
                    queryClient.invalidateQueries({ queryKey: ["RankifyGame", "getGameStateDetails", web3Context.account, rankifyInstanceAddress, gameId] });
                    queryClient.invalidateQueries({ queryKey: ["RankifyGame", "getPlayers", web3Context.account, rankifyInstanceAddress, gameId] });
                    onClose && onClose(gameId);
                }
            });
            close();
        } catch (error) {
            console.error('Error leaving game:', error);
            close();
        }
    };

    if (!isInTheGame) {
        return <></>;
    }

    return (
        <>
            <Button
                variant="light"
                color="red"
                onClick={open}
                fullWidth={fullWidth}
                loading={playersGameQuery.isLoading}
                disabled={!isInTheGame}
            >
                Leave Game
            </Button>

            <Modal opened={opened} onClose={close} title="Confirm Leave" centered>
                <Text size="sm" mb="lg">
                    Are you sure you want to leave this game?
                </Text>

                <Group justify="flex-end">
                    <Button variant="outline" onClick={close}>Cancel</Button>
                    <Button
                        onClick={handleConfirmLeave}
                        loading={leaveGameMutation.isPending || playersGameQuery.isLoading}
                    >
                        Leave
                    </Button>
                </Group>
            </Modal>
        </>
    );
};

export default LeaveGameButton;
