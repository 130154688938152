import React from 'react';
import { Button, Group, Loader, Modal, Text, useMantineTheme } from '@mantine/core';
import { Proposal } from '../../types/rankifyGame';
import { useMediaQuery } from '@mantine/hooks';
import { IconEye } from '@tabler/icons-react';
import useIpfs from '../../hooks/useIpfs';

export const ViewProposalButton: React.FC<{ proposal: Proposal }> = ({ proposal }) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const theme = useMantineTheme();
    const isXsScreen = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

    const ipfsContentQuery = useIpfs({ ipfsLink: proposal?.ipfs });

    if (ipfsContentQuery.isLoading) return <Loader />;

    if (ipfsContentQuery.isError) return <Text c="red">Error fetching proposal title</Text>;

    if (Boolean(proposal.ipfs) && ipfsContentQuery.isSuccess) {
        proposal = ipfsContentQuery.data as unknown as Proposal;
    }


    const handleView = async (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsModalOpen(true);
    };

    return (
        <>
            <Button size="xs" variant="light" visibleFrom="sm" miw="78px"
                leftSection={<IconEye size={14} />} onClick={(e) => handleView(e)}>View</Button>
            <Button size="xs" variant="light" hiddenFrom="sm" pr="3px" pl="3px" miw="45px" mr="-xs"
                onClick={(e) => handleView(e)}>View</Button>
            <Modal
                opened={isModalOpen} onClose={() => setIsModalOpen(false)}
                title={proposal.title}
                size="xl" centered={true} fullScreen={isXsScreen}
                styles={{
                    title: { fontWeight: "bold", fontSize: "1.2rem" },
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <Text>{proposal.body}</Text>
                <Group justify="right" mt="md">
                    <Button variant="outline" onClick={() => setIsModalOpen(false)}>Close</Button>
                </Group>
            </Modal>
        </>
    );
};

export default ViewProposalButton;
