import { format, formatDistance, formatDistanceStrict, formatDuration, intervalToDuration } from 'date-fns';

export function humanReadableTimeDistance(date: Date | string | number | undefined, strict = true, convertToSeconds = false): string {
  if (date == 0 || date === undefined) return '';

  const inputDate = date instanceof Date ? date : (typeof date === 'number' ? new Date(convertToSeconds ? date * 1000 : date) : new Date(date));
  const now = new Date();

  const threshold = 32 * 24 * 60 * 60 * 1000; // 32 days

  if (now.getTime() - inputDate.getTime() > threshold) {
    return format(inputDate, 'yyyy-MM-dd');
  }

  return strict ? formatDistanceStrict(inputDate, now, { addSuffix: true }) : formatDistance(inputDate, now, { addSuffix: true });
}

export function humanReadableTimeDuration(time: number): string {
  const duration = intervalToDuration({
    start: 0,
    end: time * 1000,
  });

  return formatDuration(duration);
}
