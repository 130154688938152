import { css } from '@emotion/css';

export const glowingEffect = css`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    inset: -3px;
    background: linear-gradient(45deg, #228be6, #40c057);
    border-radius: 8px;
    filter: blur(8px);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: -1;
  }
  &:not(:has(button:disabled))::after {
    animation: glow 2s infinite;
  }
  @keyframes glow {
    0%, 100% { opacity: 0.3; }
    50% { opacity: 0.6; }
  }
`;
