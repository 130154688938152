import { useMutation, useQuery } from "@tanstack/react-query";
import { InstancePlayer } from "@peeramid-labs/sdk";
import { queryCacheProps } from "./hookCommon";
import { useRankifyGameProps, VotingQueryResult, HistoricTurn, Proposal, GameState } from "../types/rankifyGame";
import useNotification from "./useNotification";
import { processSdkError } from "../utils/processError";
import { Web3ProviderInterface } from "../types";
import { Address } from "viem";
import { convertBigIntToNumber } from "../utils/bigintConverter";
import { useState, useEffect } from "react";

export const useRankifyGame = (props: useRankifyGameProps) => {
    const playerInstance = new InstancePlayer({
        chainId: props.chainId,
        publicClient: props.publicClient,
        walletClient: props.walletClient,
        instanceAddress: props.instanceAddress,
        account: props.account
    });
    const notification = useNotification();

    const useGetPlayers = () => useQuery({
        queryKey: ["RankifyGame", "getPlayers", props.account, props.instanceAddress, props.gameId],
        queryFn: async () => {
            try {
                return playerInstance.getPlayers(BigInt(props.gameId));
            } catch (error) {
                processSdkError(error, notification);
            }
        },
        ...queryCacheProps,
        refetchInterval: false,
    });

    const useVoting = (turnId: number) => useQuery({
        queryKey: ["RankifyGame", "getVoting", props.account, props.instanceAddress, props.gameId, turnId],
        queryFn: async () => {
            try {
                const voting = await playerInstance.getVoting(BigInt(props.gameId), BigInt(turnId));
                return convertBigIntToNumber(voting) as unknown as VotingQueryResult;
            } catch (error) {
                processSdkError(error, notification);
            }
        },
        enabled: turnId !== undefined && Number(turnId) > 0,
        ...queryCacheProps,
        refetchInterval: false,
    });

    const useHistoricTurn = (turnId: number) => useQuery({
        queryKey: ["RankifyGame", "getHistoricTurn", props.account, props.instanceAddress, props.gameId, turnId],
        queryFn: async () => {
            try {
                let historicTurn = await playerInstance.getHistoricTurn(BigInt(props.gameId), BigInt(turnId));
                const newProposalsParsed = historicTurn.args.newProposals?.map((proposal: unknown) => {
                    const proposalStr = proposal as string;
                    if (proposalStr.startsWith('ipfs://')) {
                        return {
                            ipfs: proposalStr
                        }
                    }
                    return JSON.parse(proposalStr)
                }) ?? [] as unknown as Proposal[];
                let convertedHistoricTurn = convertBigIntToNumber(historicTurn) as unknown as HistoricTurn;
                convertedHistoricTurn.args.newProposals = newProposalsParsed;
                return convertedHistoricTurn;
            } catch (error) {
                processSdkError(error, notification);
            }
        },
        ...queryCacheProps,
        refetchInterval: false,
    });

    const useGetOngoingProposals = () => useQuery({
        queryKey: ["RankifyGame", "getOngoingProposals", props.account, props.instanceAddress, props.gameId],
        queryFn: async () => {
            try {
                const ongoingProposals = await playerInstance.getOngoingProposals(BigInt(props.gameId));

                return ongoingProposals.proposals.map((proposal: unknown) => {
                    const proposalStr = proposal as string;
                    if (proposalStr.startsWith('ipfs://')) {
                        return {
                            ipfs: proposalStr
                        }
                    }
                    return JSON.parse(proposalStr)
                }) as unknown as Proposal[];
            } catch (error) {
                processSdkError(error, notification);
            }
        },
        ...queryCacheProps,
        refetchInterval: false,
    });

    const useGetCurrentTurnQuery = () => useQuery({
        queryKey: ["RankifyGame", "getCurrentTurn", props.account, props.instanceAddress, props.gameId],
        queryFn: async () => {
            try {
                return playerInstance.getCurrentTurn(BigInt(props.gameId));
            } catch (error) {
                processSdkError(error, notification);
            }
        },
        ...queryCacheProps,
        refetchInterval: false,
    });

    const joinGameMutation = useMutation({
        mutationFn: async () => await playerInstance.joinGame(BigInt(props.gameId)),
        onError: (error: any) => {
            processSdkError(error, notification);
        },
    });

    const leaveGameMutation = useMutation({
        mutationFn: async () => await playerInstance.leaveGame(BigInt(props.gameId)),
        onError: (error: any) => {
            processSdkError(error, notification);
        },
    });

    const startGameMutation = useMutation({
        mutationFn: async () => await playerInstance.startGame(BigInt(props.gameId)),
        onError: (error: any) => {
            processSdkError(error, notification);
        },
    });

    return {
        useGetPlayers,
        useGetCurrentTurnQuery,
        useVoting,
        useHistoricTurn,
        useGetOngoingProposals,
        joinGameMutation,
        leaveGameMutation,
        startGameMutation,
    };
};

export const usePlayersGame = (props: useRankifyGameProps) => {
    const playerInstance = new InstancePlayer({
        chainId: props.chainId,
        publicClient: props.publicClient,
        walletClient: props.walletClient,
        instanceAddress: props.instanceAddress,
        account: props.account
    });
    const notification = useNotification();
    const [isInTheGame, setIsInTheGame] = useState(false);

    const playersGameQuery = useQuery({
        queryKey: ["RankifyGame", "getPlayersGame", props.account, props.instanceAddress, props.gameId],
        queryFn: async () => {
            try {
                return playerInstance.getPlayersGame(props.account);
            } catch (error) {
                processSdkError(error, notification);
            }
        },
        ...queryCacheProps,
        refetchInterval: false,
    });

    useEffect(() => {
        if (playersGameQuery.data === BigInt(props.gameId)) {
            setIsInTheGame(true);
        } else {
            setIsInTheGame(false);
        }
    }, [playersGameQuery.data]);

    return {
        playersGameQuery,
        isInTheGame,
    };
};

export const useRankifyGameState = (props: useRankifyGameProps) => {
    const playerInstance = new InstancePlayer({
        chainId: props.chainId,
        publicClient: props.publicClient,
        walletClient: props.walletClient,
        instanceAddress: props.instanceAddress,
        account: props.account
    });
    const notification = useNotification();
    const [isGameCreator, setIsGameCreator] = useState(false);

    const gameStateDetailsQuery = useQuery({
        queryKey: ["RankifyGame", "getGameStateDetails", props.account, props.instanceAddress, props.gameId],
        queryFn: async () => {
            try {
                const gameStateDetails = await playerInstance.getGameStateDetails(BigInt(props.gameId));
                const convertedGameStateDetails = convertBigIntToNumber(gameStateDetails) as unknown as GameState;
                return { ...convertedGameStateDetails, id: props.gameId };
            } catch (error) {
                processSdkError(error, notification);
            }
        },
        enabled: props.gameId !== undefined && Number(props.gameId) > 0,
        ...queryCacheProps,
        refetchInterval: false,
    });

    useEffect(() => {
        if (gameStateDetailsQuery.data && gameStateDetailsQuery.data.createdBy === props.account) {
            setIsGameCreator(true);
        } else {
            setIsGameCreator(false);
        }
    }, [gameStateDetailsQuery.data]);

    return {
        gameStateDetailsQuery,
        isGameCreator,
    };
};

export const generateInputProps = ({
    web3ProviderContext, instanceAddress, gameId
  }: {
    web3ProviderContext: Web3ProviderInterface;
    instanceAddress: Address;
    gameId: number;
  }) => {
    if (!web3ProviderContext.currentChain) {
        throw new Error("Current chain not initialized");
    }
    if (!web3ProviderContext.account) {
        throw new Error("Account not initialized");
    }
    return {
        chainId: web3ProviderContext.currentChain.id,
        publicClient: web3ProviderContext.publicClient,
        walletClient: web3ProviderContext.walletClient,
        instanceAddress: instanceAddress,
        account: web3ProviderContext.account,
        gameId: gameId
    } as useRankifyGameProps;
};