import React from 'react';
import { Group, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { IconClock, IconStar } from '@tabler/icons-react';
import { GameState } from '../../types/rankifyGame';
import { humanReadableTimeDuration } from '../../utils/time';
import { GradientTitle } from '../GradientTitle';

export const GameSettingsInfo: React.FC<{ gameState: GameState }> = ({
    gameState,
}) => {

    return (
        <Group justify='space-between'>
            <Group gap="9px">
                <GradientTitle order={3} size='md' text="Game" gradientText='Settings' visibleFrom='md' />
                <Text visibleFrom='md' fw="bold">|</Text>
                <Tooltip label="Time per turn">
                    <ThemeIcon variant="gradient" size="md" gradient={{ from: 'teal', to: 'teal.9', deg: 90 }}>
                        <IconClock size={20} />
                    </ThemeIcon>
                </Tooltip>
                <Text size='sm'>{humanReadableTimeDuration(gameState.timePerTurn)}</Text>
                <Text visibleFrom='md' fw="bold">|</Text>
                <Tooltip label="Vote credits per turn">
                    <ThemeIcon variant="gradient" size="md" gradient={{ from: 'teal', to: 'teal.9', deg: 90 }}>
                        <IconStar size={20} />
                    </ThemeIcon>
                </Tooltip>
                <Text size='sm'>{gameState.voteCredits}</Text>
            </Group>
        </Group>
    );
};

export default GameSettingsInfo;
