import React, { useEffect, useState } from 'react';
import { Modal, Button, Group, Text, useMantineTheme, Table, Slider } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Address } from 'viem';
import useNotification from '../../hooks/useNotification';
import { motion, AnimatePresence } from 'framer-motion';
import { IconStarFilled } from '@tabler/icons-react';
import VotingStars from './votingStars/VotingStars';
import { GameState, ProposalForVoting, Proposal } from '../../types/rankifyGame';
import { useWeb3Context } from '../../providers/Web3Provider/useWeb3Context';
import { useRankifyGame, generateInputProps } from '../../hooks/useRankifyGame';
import { Loader } from '../Loader';
import { AlertMessage } from '../AlertMessage';
import useGameMasterBackend from '../../hooks/useGameMasterBackend';
import { useForm } from '@mantine/form';
import { useQueryClient } from '@tanstack/react-query';
import ViewProposalButton from './ViewProposalButton';
import ProposalTitle from './ProposalTitle';

interface VoteModalProps {
    gameState: GameState;
    rankifyInstanceAddress: Address;
    opened: boolean;
    onClose: (votes?: number[]) => void;
}

export const VoteModal: React.FC<VoteModalProps> = ({
    gameState,
    rankifyInstanceAddress,
    opened,
    onClose
}) => {
    const web3Context = useWeb3Context();
    const { useGetOngoingProposals } = useRankifyGame(generateInputProps({ web3ProviderContext: web3Context, instanceAddress: rankifyInstanceAddress, gameId: gameState.id }));
    const ongoingProposalsQuery = useGetOngoingProposals();
    const { useMyProposalId, submitVotesMutation } = useGameMasterBackend();
    const myProposalIdQuery = useMyProposalId({ rankifyInstanceAddress, turnId: gameState.currentTurn - 1, gameId: gameState.id });
    const ongoingProposals: Proposal[] | undefined = ongoingProposalsQuery.data;
    const queryClient = useQueryClient();
    const [isAnimating, setIsAnimating] = useState(false);
    const [votingRefs] = useState<Map<number, React.RefObject<{ reset: () => void }>>>(() => new Map());
    const notification = useNotification();
    const theme = useMantineTheme();
    const isXsScreen = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
    const myProposalIndex = Number(myProposalIdQuery.data);

    const totalPoints: number = gameState.voting.voteCredits;
    const marks = [{ value: 0, 'label': '0' }, { value: totalPoints, 'label': totalPoints.toString() }];
    const [pointsLeft, setPointsLeft] = useState(totalPoints);

    const [proposals, setProposals] = useState<ProposalForVoting[]>([]);

    useEffect(() => {
        if (ongoingProposals !== undefined && myProposalIndex !== undefined) {
            setProposals(ongoingProposals.map((proposal, index) => ({
                id: index,
                proposal: proposal,
                score: 0,
                isCurrentPlayerProposal: index === myProposalIndex
            })));
        }
    }, [myProposalIndex, ongoingProposals])


    const form = useForm({
        initialValues: {
            creditsLeft: totalPoints,
        },
        validate: {
            creditsLeft: (value) =>
                value === 0
                    ? null
                    : "All credits must be used.",
        },
    });

    if (ongoingProposalsQuery.isLoading || myProposalIdQuery.isLoading) return <Loader />;
    if (ongoingProposalsQuery.isError || myProposalIdQuery.isError
        || ongoingProposalsQuery.data === undefined || myProposalIdQuery.data === undefined) return (
            <AlertMessage
                message="There was an error fetching ongoing proposals! Please try again."
            />
        );

    const handleReset = () => {
        votingRefs.forEach((ref) => {
            ref.current?.reset();
        });
        setProposals(proposals?.map(item => ({ ...item, score: 0 })));
        setPointsLeft(totalPoints);
        form.setFieldValue('creditsLeft', totalPoints);
        setIsAnimating(false);
    };

    const setScore = (proposalId: number) => (points: number) => {
        const updatedItems = proposals?.map((item) =>
            item.id === proposalId ? { ...item, score: points } : item
        );
        const sortedItems = [...updatedItems].sort((a, b) => b.score - a.score);
        setProposals(sortedItems);
        const newPointsLeft = pointsLeft - points;
        setPointsLeft(newPointsLeft);
        form.setFieldValue('creditsLeft', newPointsLeft);
        setIsAnimating(true);
        setTimeout(() => setIsAnimating(false), 300);
    };

    const unsetScore = (proposalId: number) => (points: number) => {
        const updatedItems = proposals?.map((item) =>
            item.id === proposalId ? { ...item, score: item.score - points } : item
        );
        const sortedItems = [...updatedItems].sort((a, b) => b.score - a.score);
        setProposals(sortedItems);
        const newPointsLeft = pointsLeft + points;
        setPointsLeft(newPointsLeft);
        form.setFieldValue('creditsLeft', newPointsLeft);
    };

    const handleSubmit = async () => {
        const validation = form.validate();
        if (validation.hasErrors) {
            return;
        }
        try {
            const sortedProposalsById = [...proposals].sort((a, b) => a.id - b.id);
            const vote: number[] = sortedProposalsById.map((item) => item.score);
            const quadraticVote: number[] = vote.map((item) => Math.floor(Math.sqrt(item)));
            await submitVotesMutation.mutateAsync({
                rankifyInstanceAddress,
                vote: quadraticVote,
                gameId: gameState.id.toString()
            });
            notification('Vote submitted successfully', 'success');
            queryClient.invalidateQueries({ queryKey: ["RankifyGame", "getVoting", web3Context.account, rankifyInstanceAddress, gameState.id, gameState.currentTurn] });
            onClose();
        } catch (error) {
            notification('Failed to submit vote', 'error');
        }
    };

    return (
        <Modal
            opened={opened}
            onClose={onClose}
            title="Cast Your Vote"
            size="lg"
            centered={true}
            fullScreen={isXsScreen}
            onClick={(e) => e.stopPropagation()}
        >
            <form onSubmit={form.onSubmit((values) => handleSubmit())}>
                <Text my="sm">Points left: {pointsLeft}</Text>
                {form.errors.creditsLeft && (
                    <Text color="red" size="sm" mb="sm">
                        {form.errors.creditsLeft}
                    </Text>
                )}
                <motion.div
                    style={{ display: "inline-block", position: "relative", width: "100%" }}
                    animate={isAnimating ? { scale: 1.05, boxShadow: "0px 0px 15px rgba(0, 128, 128, 0.6)" } : {}}
                    transition={{ type: "spring", stiffness: 300, damping: 15 }}
                >
                    <Slider
                        {...form.getInputProps("creditsLeft")}
                        min={1} max={totalPoints} mb="lg" size="lg"
                        defaultValue={1} label={(val) => val.toString()} marks={marks}
                        value={pointsLeft} color="teal"
                        thumbChildren={<IconStarFilled color="orange" size={18} />}
                        thumbSize={26} disabled={pointsLeft === 0}
                        styles={{
                            markLabel: { fontSize: 16, fontWeight: 500, color: theme.colors.gray[2] },
                            thumb: { transition: 'transform 300ms ease, left 300ms ease' },
                            bar: { transition: 'width 300ms ease' }
                        }}
                    />
                </motion.div>
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Proposal</Table.Th>
                            <Table.Th style={{ textAlign: 'right' }}>Points</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        <AnimatePresence>
                            {proposals.map((item) => (
                                <motion.tr
                                    key={item.id}
                                    layout
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    style={{ display: 'table-row', borderBottom: `1px solid ${theme.colors.dark[4]}` }}
                                >
                                    <Table.Td pl="0" pt="xs" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <ViewProposalButton proposal={item.proposal} />
                                        <Text style={{ wordBreak: 'break-word', whiteSpace: 'normal' }} lineClamp={2} c={item.isCurrentPlayerProposal ? 'orange.6' : ''}>
                                            <ProposalTitle proposal={item.proposal} />
                                        </Text>
                                    </Table.Td>
                                    <Table.Td px="0" style={{ textAlign: 'right', whiteSpace: 'nowrap', minWidth: totalPoints > 14 ? '127px' : '77px' }}>
                                        <VotingStars
                                            ref={votingRefs.get(item.id)}
                                            pointsTotal={totalPoints}
                                            pointsLeft={pointsLeft}
                                            pointsSetInitially={item.score}
                                            scoreSet={setScore(item.id)}
                                            scoreUnset={unsetScore(item.id)}
                                            disabled={item.isCurrentPlayerProposal}
                                        />
                                    </Table.Td>
                                </motion.tr>
                            ))}
                        </AnimatePresence>
                    </Table.Tbody>
                </Table>

                <Group justify="flex-end" mt="xl"
                    style={{
                        position: 'sticky', bottom: 0, zIndex: 3,
                        background: theme.colors.dark[7],
                        padding: '1rem', paddingLeft: '2rem', paddingRight: '2rem',
                        margin: 0, marginLeft: '-1rem', marginRight: '-1rem',
                        boxShadow: 'rgba(0, 0, 0, 0.05) 0px -4px 12px'
                    }}>
                    <Button variant="outline" onClick={onClose} loading={submitVotesMutation.isPending}>
                        Back
                    </Button>
                    <Button variant="outline" onClick={handleReset} loading={submitVotesMutation.isPending}>
                        Reset
                    </Button>
                    <Button type="submit" loading={submitVotesMutation.isPending}>
                        Submit
                    </Button>
                </Group>
            </form>
        </Modal>
    );
};

export default VoteModal;
