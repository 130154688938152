import React from 'react';
import { Button, Tooltip, useMantineTheme } from '@mantine/core';
import { Address } from 'viem';
import useGameMasterBackend from '../../hooks/useGameMasterBackend';
import { GameState } from '../../types/rankifyGame';
import { IconBulb } from '@tabler/icons-react';
import { useWeb3Context } from '../../providers/Web3Provider/useWeb3Context';
import { useRankifyGame, generateInputProps } from '../../hooks/useRankifyGame';
import ProposalModal from './ProposalModal';
import { motion } from 'framer-motion';
import { useMediaQuery } from '@mantine/hooks';
import { glowingEffect } from '../../styles/animations';
import YoyoEffectWithGlow from '../animations/YoyoEffectWithGlow';

interface ProposeButtonProps {
    gameState: GameState;
    rankifyInstanceAddress: Address;
}

export const ProposeButton: React.FC<ProposeButtonProps> = ({ gameState, rankifyInstanceAddress }) => {
    const web3Context = useWeb3Context();
    const { useVoting } = useRankifyGame(generateInputProps({ web3ProviderContext: web3Context, instanceAddress: rankifyInstanceAddress, gameId: gameState.id }));
    const { submitProposalMutation } = useGameMasterBackend();
    const proposalQuery = useVoting(gameState.currentTurn);
    const [isProposalModalOpen, setIsProposalModalOpen] = React.useState(false);
    const proposed = proposalQuery.data?.proposalEvents?.some(event => event.args.proposer === web3Context.account) ?? false;
    const theme = useMantineTheme();
    const isSmScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

    const handleProposeBtnClick = async (e: React.MouseEvent) => {
        try {
            e.stopPropagation();
            setIsProposalModalOpen(true);
        } catch (error) {
            console.error('Error proposing turn:', error);
        }
    };

    return (
        <>
            {!isSmScreen && (
                <YoyoEffectWithGlow disabled={proposed}>
                    <Tooltip label="Already proposed" disabled={!proposed}>
                        <Button disabled={proposed} loading={submitProposalMutation.isPending || proposalQuery.isLoading}
                            size="xs" variant="light" fullWidth leftSection={<IconBulb size={22} />} onClick={(e) => handleProposeBtnClick(e)}>
                            Propose
                        </Button>
                    </Tooltip>
                </YoyoEffectWithGlow>
            )}
            {isSmScreen && (
                <Tooltip label="Already proposed" disabled={!proposed}>
                    <YoyoEffectWithGlow disabled={proposed}>
                        <Button disabled={proposed} loading={submitProposalMutation.isPending || proposalQuery.isLoading}
                            size="xs" fullWidth variant="light" pr="3px" pl="3px" onClick={(e) => handleProposeBtnClick(e)}>
                            Propose
                        </Button>
                    </YoyoEffectWithGlow>
                </Tooltip>
            )}

            {isProposalModalOpen && <ProposalModal
                gameState={gameState}
                rankifyInstanceAddress={rankifyInstanceAddress}
                opened={isProposalModalOpen}
                onClose={() => { setIsProposalModalOpen(false); }}
            />}
        </>
    );
};

export default ProposeButton;
