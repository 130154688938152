import React from 'react';
import { Modal, Button, Group, Text, useMantineTheme, TextInput } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import Highlight from '@tiptap/extension-highlight';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { RichTextEditor } from '@mantine/tiptap';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import Placeholder from '@tiptap/extension-placeholder';
import { Address } from 'viem';
import useGameMasterBackend from '../../hooks/useGameMasterBackend';
import useNotification from '../../hooks/useNotification';
import { GameState, Proposal } from '../../types/rankifyGame';
import { useForm } from "@mantine/form";
import { useQueryClient } from '@tanstack/react-query';
import { useWeb3Context } from '../../providers/Web3Provider/useWeb3Context';
import '@mantine/core/styles.css';
import '@mantine/tiptap/styles.css';

interface ProposalModalProps {
    gameState: GameState;
    rankifyInstanceAddress: Address;
    proposal?: Proposal;
    opened: boolean;
    onClose: () => void;
}

export const ProposalModal: React.FC<ProposalModalProps> = ({
    gameState,
    rankifyInstanceAddress,
    proposal,
    opened,
    onClose
}) => {
    const web3Context = useWeb3Context();
    const { submitProposalMutation } = useGameMasterBackend();
    const queryClient = useQueryClient();
    const notification = useNotification();
    const theme = useMantineTheme();
    const isXsScreen = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
    const view = proposal !== undefined;

    const form = useForm({
        initialValues: {
            title: proposal?.title || "",
            body: proposal?.body || ""
        },
        validate: {
            title: (value) =>
                value.length >= 3 && value.length <= 50
                    ? null
                    : "Enter a title between 3 and 50 characters.",
            body: (value) =>
                value.length >= 10 && value.length <= 2000
                    ? null
                    : "Proposal content must be between 10 and 2000 characters.",
        },
    });

    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Superscript,
            Subscript,
            Highlight,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
            Placeholder.configure({ placeholder: 'What\'s your next big brainwave? Share it here...' }),
        ],
        content: form.values.body,
        editable: !view,
        editorProps: {
            attributes: {
                class: 'rich-text-editor',
            },
        },
        onCreate: ({ editor }) => {
            editor.setEditable(!view);
        },
        onUpdate: ({ editor }) => {
            form.setFieldValue('body', editor.getHTML());
        },
    });

    const handleSubmit = async (values: { title: string; body: string }) => {
        try {
            if (!editor) return;

            await submitProposalMutation.mutateAsync({
                rankifyInstanceAddress,
                proposal: JSON.stringify({
                    title: values.title,
                    body: values.body
                }),
                gameId: gameState.id.toString(),
                turn: gameState.currentTurn.toString()
            });
            notification("Proposal submitted successfully", "success");
            queryClient.invalidateQueries({ queryKey: ["RankifyGame", "getVoting", web3Context.account, rankifyInstanceAddress, gameState.id, gameState.currentTurn] });
            onClose();
        } catch (error) {
            notification("Failed to submit proposal", "error");
        }
    };

    return (
        <Modal
            opened={opened} onClose={onClose}
            title={view ? "View Proposal" : "Submit Proposal"}
            size="xl" centered={true} fullScreen={isXsScreen}
            styles={{
                title: { fontWeight: "bold", fontSize: "1.2rem" },
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <TextInput
                        label="Proposal title"
                        description={!view && "Provide a short, descriptive title for your proposal"}
                        placeholder="Enter a title" mb="md"
                        {...form.getInputProps("title")}
                        {...(view ? { value: proposal.title } : {})}
                        readOnly={view}
                    />
                    <Text size="sm" fw={500} mb={4}>Proposal content</Text>
                    {!view && <Text size="xs" c="dimmed" mb="xs">Share your proposal in detail</Text>}
                    {form.errors.body && (
                        <Text c="red" size="sm" mt={4}>
                            {form.errors.body}
                        </Text>
                    )}
                    <RichTextEditor editor={editor} styles={{
                        root: {
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: 400,
                            borderColor: form.errors.body ? 'var(--mantine-color-error)' : undefined
                        },
                        content: { flex: 1, minHeight: 400 }
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}>
                        {!view && <RichTextEditor.Toolbar sticky stickyOffset={60}>
                            <RichTextEditor.ControlsGroup>
                                <RichTextEditor.Bold />
                                <RichTextEditor.Italic />
                                <RichTextEditor.Underline />
                                <RichTextEditor.Highlight />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                                <RichTextEditor.H1 />
                                <RichTextEditor.H2 />
                                <RichTextEditor.H3 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                                <RichTextEditor.Link />
                                <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                                <RichTextEditor.AlignLeft />
                                <RichTextEditor.AlignCenter />
                                <RichTextEditor.AlignJustify />
                                <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>
                            <RichTextEditor.ControlsGroup>
                                <RichTextEditor.BulletList />
                                <RichTextEditor.OrderedList />
                                <RichTextEditor.CodeBlock />
                                <RichTextEditor.Subscript />
                                <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>
                        </RichTextEditor.Toolbar>}

                        <RichTextEditor.Content />
                    </RichTextEditor>

                    <Group mt="md" justify="end"
                        style={{
                            position: 'sticky', bottom: 0, zIndex: 3,
                            background: theme.colors.dark[7],
                            padding: '1rem', paddingLeft: '2rem', paddingRight: '2rem',
                            margin: 0, marginLeft: '-1rem', marginRight: '-1rem',
                            boxShadow: 'rgba(0, 0, 0, 0.05) 0px -4px 12px'
                        }}>
                        <Button variant="outline" onClick={() => { onClose()}} loading={submitProposalMutation.isPending}>Close</Button>
                        {!view && <Button type="submit" loading={submitProposalMutation.isPending}>Submit</Button>}
                    </Group>
                </div>
            </form>
        </Modal>
    );
};

export default ProposalModal;
