import React from 'react';
import { motion } from 'framer-motion';
import { glowingEffect } from '../../styles/animations';

interface YoyoEffectWithGlowProps {
    children: React.ReactNode;
    disabled?: boolean;
    duration?: number;
    scale?: [number, number, number];
}

export const YoyoEffectWithGlow: React.FC<YoyoEffectWithGlowProps> = ({
    children,
    disabled = false,
    duration = 1,
    scale = [1, 1.06, 1]
}) => {
    return (
        <motion.div
            className={glowingEffect}
            animate={!disabled ? { scale } : {}}
            transition={!disabled ? { repeat: Infinity, duration } : {}}
        >
            {children}
        </motion.div>
    );
};

export default YoyoEffectWithGlow;
