import React from 'react';
import { Tooltip, Text, TextProps } from '@mantine/core';
import { useWeb3Context } from '../providers/Web3Provider/useWeb3Context';
import { Address } from 'viem';
import { useMultipass, generateInputProps } from '../hooks';


const UserName = (address: Address | null): string => {
    const web3ProviderContext = useWeb3Context();
    const { username } = useMultipass(generateInputProps({ web3ProviderContext, account: address }));

    if (!address) {
        return "";
    }
    // Map specific addresses to hardcoded usernames
    const addressMap: { [key: string]: string } = {
        '0xBEA0426e21891CF9f13ABE8000E5FfFd01Aa64E5': 'CryptoWhale',
        '0x8bC03532fE2a0c81dD8C931966a8f2dd65601977': 'TokenMaster',
        '0x266a36274E3eA766A683B9FDdb5231AA95914981': 'BlockchainGuru',
        '0x2a1b790FA3339e950efF01831ABaDc4A28Eb48c2': 'CryptoWizard',
    };

    const resolvedUsername = username ?? addressMap[address] ?? null;

    // Return mapped username if exists, otherwise return shortened address
    return resolvedUsername ||
        `${address.slice(0, 6)}...${address.slice(-3)}`;
};

const Username: React.FC<{ address: Address | null, noTooltip?: boolean } & TextProps> = ({ address, noTooltip = false, ...textProps }) => {
    const web3Context = useWeb3Context();
    const displayName = UserName(address);

    return (
        <Tooltip label={address} position="top" disabled={noTooltip}>
            <Text
                {...textProps}
                c={address === web3Context.account ? 'orange.6' : ''}
            >
                {displayName}
            </Text>
        </Tooltip>
    );
};

export default Username;
