import React from 'react';
import { Button, Tooltip, useMantineTheme } from '@mantine/core';
import { Address } from 'viem';
import { IconStar } from '@tabler/icons-react';
import { useWeb3Context } from '../../providers/Web3Provider/useWeb3Context';
import { useRankifyGame, generateInputProps } from '../../hooks/useRankifyGame';
import VoteModal from './VoteModal';
import { GameState } from '../../types/rankifyGame';
import { useMediaQuery } from '@mantine/hooks';
import YoyoEffectWithGlow from '../animations/YoyoEffectWithGlow';

interface VoteButtonProps {
    gameState: GameState;
    rankifyInstanceAddress: Address;
}

export const VoteButton: React.FC<VoteButtonProps> = ({ gameState, rankifyInstanceAddress }) => {
    const web3Context = useWeb3Context();
    const { useVoting } = useRankifyGame(generateInputProps({ web3ProviderContext: web3Context, instanceAddress: rankifyInstanceAddress, gameId: gameState.id }));
    const currentTurnVotingQuery = useVoting(gameState.currentTurn);
    const [isVoteModalOpen, setIsVoteModalOpen] = React.useState(false);

    const voted = currentTurnVotingQuery.data?.voteEvents?.some(event => event.args.player === web3Context.account) ?? false;
    const tooltipText = gameState.currentTurn > 1 ? "Already voted" : "There is no voting in 1st turn";
    const isDisabled = gameState.currentTurn === 1 || voted;

    const theme = useMantineTheme();
    const isSmScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

    const handleVoteBtnClick = async (e: React.MouseEvent) => {
        try {
            e.stopPropagation();
            setIsVoteModalOpen(true);
        } catch (error) {
            console.error('Error opening vote modal:', error);
        }
    };

    return (
        <>
            {!isSmScreen && (
                <YoyoEffectWithGlow disabled={isDisabled}>
                    <Tooltip label={tooltipText} disabled={!isDisabled}><Button disabled={isDisabled} loading={currentTurnVotingQuery.isLoading}
                        size="xs" variant="light"
                        fullWidth leftSection={<IconStar size={22} />} onClick={(e) => handleVoteBtnClick(e)}>
                        Vote</Button></Tooltip>
                </YoyoEffectWithGlow>
            )}
            {isSmScreen && (
                <YoyoEffectWithGlow disabled={isDisabled}>
                    <Tooltip label={tooltipText} disabled={!isDisabled}>
                        <Button disabled={isDisabled} loading={currentTurnVotingQuery.isLoading}
                            size="xs" variant="light" fullWidth
                            hiddenFrom="sm" pr="3px" pl="3px" onClick={(e) => handleVoteBtnClick(e)}>
                            Vote</Button>
                    </Tooltip>
                </YoyoEffectWithGlow>)}
            {isVoteModalOpen && <VoteModal
                gameState={gameState}
                rankifyInstanceAddress={rankifyInstanceAddress}
                opened={isVoteModalOpen}
                onClose={() => setIsVoteModalOpen(false)}
            />}
        </>
    );
};

export default VoteButton;
